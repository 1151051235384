<template>
  <!-- Page Content  -->
  <b-container fluid>
    <!-- BEGIN:Modal Termin -->
    <b-modal
      id="modal-termin"
      v-model="isModalTermin"
      size="xl"
      centered
      title="Tambah Data Termin Pembayaran"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group
          label="Kode KS:"
          label-for="kodePks"
          label-class="text-dark"
        >
          <input-primary
            id="kodePks"
            v-model="termin.kodePks"
            required
            isDetail
            type="number"
            max-length="30"
          />
        </b-form-group>
        <b-form-group
          label="Angsuran Ke:"
          label-for="angsuranKe"
          label-class="text-dark"
        >
          <input-primary
            id="angsuranKe"
            v-model="termin.angsuranKe"
            required
            isDetail
            type="number"
            max-length="30"
          />
        </b-form-group>
        <b-form-group
          label="Tanggal Jatuh Tempo:"
          label-for="tglJatuhTempo"
          label-class="text-dark"
        >
          <flat-pickr
            id="tglJatuhTempo"
            class="form-control bg-white"
            :config="basicMinTimeConfig"
            v-model="termin.tglJatuhTempo"
            placeholder="Tanggal Jatuh Tempo"
          ></flat-pickr>
        </b-form-group>
        <b-form-group>
          <label for="nominalKs">Nominal KS:</label>
          <b-row>
            <b-col>
              <input-primary
                id="nominalKs"
                v-model="termin.nominalKs"
                required
                type="number"
                max-length="30"
                isDetail
              />
            </b-col>
            <b-col class="align-self-center" md="1">
              <span class="text-danger">(x1000)</span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <label for="sisaNominal">Sisa Pembayaran:</label>
          <b-row>
            <b-col>
              <input-primary
                id="sisaNominal"
                v-model="termin.sisaNominal"
                required
                type="number"
                max-length="30"
                isDetail
              />
            </b-col>
            <b-col class="align-self-center" md="1">
              <span class="text-danger">(x1000)</span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <label for="jumlahPembayaran">Jumlah Pembayaran:</label>
          <b-row>
            <b-col>
              <input-primary
                id="jumlahPembayaran"
                v-model="termin.jumlahPembayaran"
                required
                type="number"
                max-length="30"
              />
            </b-col>
            <b-col class="align-self-center" md="1">
              <span class="text-danger">(x1000)</span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalTermin">Kembali</b-btn>
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="createTermin"
              :disabled="!isValidTermin"
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
      <hr class="mt-4" />
      <div class="align-items-center px-4">
        <b-table
          responsive
          bordered
          :fields="theadTermin"
          :items="listDataTermin"
          class="mt-2"
          :busy="isLoadingTermin"
          :per-page="perPageTermin"
          :current-page="currentPageTermin"
          @filtered="onFilteredTermin"
          show-empty
        >
          <template v-slot:cell(aksi)="listDataTermin">
            <div class="d-flex">
              <!-- <b-button
                class="mx-1"
                variant=" iq-bg-success"
                size="sm"
                @click="
                  showModalEditTermin(termin.kodePks, listDataTermin.item)
                "
                ><i class="ri-ball-pen-fill m-0"></i>Ubah</b-button
              > -->
              <div
                :class="
                  termin.angsuranKe - 1 != listDataTermin.item.angsuran
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer'
                "
                v-b-tooltip="
                  termin.angsuranKe - 1 != listDataTermin.item.angsuran
                    ? 'Hapus angsuran yang terbaru lebih dulu'
                    : 'Hapus Data'
                "
              >
                <b-button
                  class="mx-1"
                  :variant="
                    termin.angsuranKe - 1 != listDataTermin.item.angsuran
                      ? ' iq-bg-dark'
                      : ' iq-bg-danger'
                  "
                  size="sm"
                  @click="
                    showModalDeleteTermin(termin.kodePks, listDataTermin.item)
                  "
                  :disabled="
                    termin.angsuranKe - 1 != listDataTermin.item.angsuran
                  "
                  ><i class="ri-delete-bin-line m-0"></i>Hapus</b-button
                >
              </div>
            </div>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #empty>
            <div class="text-danger font-weight-bold mx-auto text-center">
              Data tidak ditemukan
            </div>
          </template>
          <template #emptyfiltered>
            <div class="text-danger font-weight-bold mx-auto text-center">
              Data tidak ditemukan
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col>
            <b-form-group
              label="Per page:"
              label-for="per-page-select"
              label-size="sm"
              class="d-flex"
            >
              <b-form-select
                class="ml-2"
                id="per-page-select"
                v-model="perPageTermin"
                :options="pageOptionsTermin"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-pagination
              class="float-right"
              v-model="currentPageTermin"
              :total-rows="totalRowsTermin"
              :per-page="perPageTermin"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <!-- ./END:Modal Termin -->
    <!-- BEGIN:Modal Edit Termin -->
    <b-modal
      id="modal-edit-termin"
      v-model="isModalEditTermin"
      size="lg"
      centered
      title="Ubah Data Termin Pembayaran"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group
          label="Kode KS:"
          label-for="kodePks"
          label-class="text-dark"
        >
          <input-primary
            id="kodePks"
            v-model="terminEdit.kodePks"
            required
            isDetail
            type="number"
            max-length="30"
          />
        </b-form-group>
        <b-form-group
          label="Angsuran Ke:"
          label-for="angsuran"
          label-class="text-dark"
        >
          <input-primary
            id="angsuran"
            v-model="terminEdit.angsuran"
            required
            isDetail
            type="number"
            max-length="30"
          />
        </b-form-group>
        <b-form-group
          label="Tanggal Jatuh Tempo:"
          label-for="tglJatuhTempo"
          label-class="text-dark"
        >
          <flat-pickr
            id="tglJatuhTempo"
            class="form-control bg-white"
            :config="basicMinTimeConfig"
            v-model="terminEdit.tglJatuhTempo"
            placeholder="Tanggal Jatuh Tempo"
          ></flat-pickr>
        </b-form-group>
        <b-form-group>
          <label for="jumlahPembayaran">Jumlah Pembayaran:</label>
          <b-row>
            <b-col>
              <input-primary
                id="jumlahPembayaran"
                v-model="terminEdit.jumlahPembayaran"
                required
                type="number"
                max-length="30"
              />
            </b-col>
            <b-col class="align-self-center" md="1">
              <span class="text-danger">(x1000)</span>
            </b-col>
          </b-row>
        </b-form-group>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalEditTermin"
              >Kembali</b-btn
            >
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="editTermin"
              :disabled="!isValidTerminEdit"
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Edit Termin -->
    <!-- BEGIN:Modal Delete Termin -->
    <b-modal
      id="modal-delete-termin"
      v-model="isModalDeleteTermin"
      centered
      title="Hapus Data Termin Pembayaran"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="danger"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <h5 class="text-center">
          Apakah anda yakin ingin menghapus data<br /><b
            >Angsuran ke {{ terminDelete.angsuran }}</b
          >?
        </h5>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="isModalDeleteTermin = false"
              >Batal</b-btn
            >
          </b-col>
          <b-col>
            <b-btn class="btn-block" variant="success" @click="deleteTermin"
              >Hapus</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Delete Termin -->
    <!-- BEGIN:Modal Realisasi -->
    <b-modal
      id="modal-realisasi"
      v-model="isModalRealisasi"
      size="xl"
      centered
      title="Tambah Data Realisasi Pembayaran"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group
          label="Kode KS:"
          label-for="kodePks"
          label-class="text-dark"
        >
          <input-primary
            id="kodePks"
            v-model="realisasi.kodePks"
            required
            isDetail
            type="number"
            max-length="30"
          />
        </b-form-group>
        <b-form-group
          label="Angsuran Ke:"
          label-for="angsuran"
          label-class="text-dark"
        >
          <input-primary
            id="angsuran"
            v-model="realisasi.angsuran"
            required
            @input="showDataRealisasi($event)"
            type="number"
            max-length="30"
          />
        </b-form-group>
        <b-form-group
          label="Jumlah Angsuran:"
          label-for="jumlah_angsuran"
          label-class="text-dark"
        >
          <input-primary
            id="jumlah_angsuran"
            v-model="realisasi.jumlah_angsuran"
            required
            isDetail
            type="number"
            max-length="30"
          />
        </b-form-group>
        <b-form-group
          label="Tanggal Pembayaran:"
          label-for="tanggal_pembayaran"
          label-class="text-dark"
        >
          <flat-pickr
            id="tanggal_pembayaran"
            class="form-control bg-white"
            :config="basicConfig"
            v-model="realisasi.tanggal_pembayaran"
            placeholder="Tanggal Pembayaran"
          ></flat-pickr>
        </b-form-group>
        <b-form-group
          label="Cara Pembayaran:"
          label-for="cara_pembayaran"
          label-class="text-dark"
        >
          <input-primary
            id="cara_pembayaran"
            v-model="realisasi.cara_pembayaran"
            required
            max-length="100"
          />
        </b-form-group>
        <b-form-group
          label="Tanggal Jatuh Tempo:"
          label-for="tanggal_jatuh_tempo"
          label-class="text-dark"
        >
          <flat-pickr
            id="tanggal_jatuh_tempo"
            class="form-control bg-light cursor-not-allowed"
            :config="basicConfig"
            v-model="realisasi.tanggal_jatuh_tempo"
            placeholder="Tanggal Jatuh Tempo"
            disabled
          ></flat-pickr>
        </b-form-group>
        <b-form-group>
          <label for="lokasi_dokumen">Lokasi Dokumen:</label>
          <b-form-file
            placeholder="Pilih Dokumen"
            id="lokasi_dokumen"
            v-model="realisasi.lokasi_dokumen"
          ></b-form-file>
        </b-form-group>
        <b-form-group
          label="Nomor Surat Tagihan:"
          label-for="nomor_surat_tagihan"
          label-class="text-dark"
        >
          <input-primary
            id="nomor_surat_tagihan"
            v-model="realisasi.nomor_surat_tagihan"
            required
            type="number"
            max-length="100"
          />
        </b-form-group>
        <div
          v-if="
            listIdGroup.PUSKOM.includes(idGroup) ||
              listIdGroup.PJ_KEUANGAN_PKS.includes(idGroup)
          "
        >
          <h5 class="mb-2 font-weight-bold">VERIFIKASI</h5>
          <b-form-group
            label="Verifikasi:"
            label-for="verifikasi"
            label-class="text-dark cursor-pointer"
            label-cols-sm="1"
          >
            <b-form-checkbox
              id="verifikasi"
              class="align-self-center"
              size="lg"
              v-model="realisasi.verifikasi"
              required
            ></b-form-checkbox>
          </b-form-group>
          <b-form-group
            label="Keterangan:"
            label-for="keterangan_verifikasi"
            label-class="text-dark"
          >
            <input-primary
              id="keterangan_verifikasi"
              v-model="realisasi.keterangan_verifikasi"
              required
              max-length="100"
            />
          </b-form-group>
        </div>
        <h5
          class="mb-2 font-weight-bold"
          v-if="
            listIdGroup.PUSKOM.includes(idGroup) ||
              listIdGroup.DAAK.includes(idGroup) ||
              listIdGroup.UPBJJ.includes(idGroup)
          "
        >
          JAMINAN
        </h5>
        <div
          v-if="
            listIdGroup.PUSKOM.includes(idGroup) ||
              listIdGroup.DAAK.includes(idGroup)
          "
        >
          <b-form-group
            label="Jaminan:"
            label-for="jaminan"
            label-class="text-dark cursor-pointer"
            label-cols-sm="1"
          >
            <b-form-checkbox
              id="jaminan"
              class="align-self-center"
              size="lg"
              v-model="realisasi.jaminan"
              required
            ></b-form-checkbox>
          </b-form-group>
        </div>
        <div
          v-if="
            listIdGroup.PUSKOM.includes(idGroup) ||
              listIdGroup.UPBJJ.includes(idGroup)
          "
        >
          <b-form-group>
            <label for="lokasi_dokumen_jaminan">Lokasi Dokumen Jaminan:</label>
            <b-form-file
              placeholder="Pilih Dokumen"
              id="lokasi_dokumen_jaminan"
              v-model="realisasi.lokasi_dokumen_jaminan"
            ></b-form-file>
          </b-form-group>
        </div>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalRealisasi"
              >Kembali</b-btn
            >
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="createRealisasi"
              :disabled="!isValidRealisasi"
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
      <hr class="mt-4" />
      <div class="align-items-center px-4">
        <b-table
          responsive
          bordered
          :fields="theadRealisasi"
          :items="dataRealisasi"
          class="mt-2"
          :busy="isLoadingRealisasi"
          :per-page="perPageRealisasi"
          :current-page="currentPageRealisasi"
          @filtered="onFilteredRealisasi"
          show-empty
        >
          <template v-slot:cell(dokumen)>
            <div class="d-flex">
              <b-button
                class="mx-1"
                variant=" iq-bg-primary"
                v-b-tooltip="'Bukti Realisasi'"
                size="sm"
                ><i class="ri-file-fill m-0"></i>Realisasi</b-button
              >
              <b-button
                class="mx-1"
                variant=" iq-bg-primary"
                v-b-tooltip="'Dokumen Jaminan'"
                size="sm"
                ><i class="ri-file-fill m-0"></i>Jaminan</b-button
              >
            </div>
          </template>
          <template v-slot:cell(aksi)="dataRealisasi">
            <b-button
              class="mx-1"
              variant=" iq-bg-danger"
              v-b-tooltip="'Hapus Data'"
              size="sm"
              @click="showModalDeleteRealisasi(dataRealisasi.item)"
              ><i class="ri-delete-bin-line m-0"></i>Hapus</b-button
            >
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #empty>
            <div class="text-danger font-weight-bold mx-auto text-center">
              Data tidak ditemukan
            </div>
          </template>
          <template #emptyfiltered>
            <div class="text-danger font-weight-bold mx-auto text-center">
              Data tidak ditemukan
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col>
            <b-form-group
              label="Per page:"
              label-for="per-page-select"
              label-size="sm"
              class="d-flex"
            >
              <b-form-select
                class="ml-2"
                id="per-page-select"
                v-model="perPageRealisasi"
                :options="pageOptionsRealisasi"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-pagination
              class="float-right"
              v-model="currentPageRealisasi"
              :total-rows="totalRowsRealisasi"
              :per-page="perPageRealisasi"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <!-- ./END:Modal Realisasi -->
    <!-- BEGIN:Modal Delete Realisasi -->
    <b-modal
      id="modal-delete-realisasi"
      v-model="isModalDeleteRealisasi"
      centered
      title="Hapus Data Realisasi Pembayaran"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="danger"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <h5 class="text-center">
          Apakah anda yakin ingin menghapus data<br /><b
            >Angsuran ke {{ realisasiDelete.angsuran }}</b
          >?
        </h5>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="isModalDeleteRealisasi = false"
              >Batal</b-btn
            >
          </b-col>
          <b-col>
            <b-btn class="btn-block" variant="success" @click="deleteRealisasi"
              >Hapus</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Delete Realisasi -->
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Daftar Data Kontrak</h4>
          </template>
          <template v-slot:body>
            <!-- <div class="d-inline-block float-right mb-4">
              <router-link
                class="btn btn-primary dark-btn-primary"
                :to="{ name: 'kerjasama.kontrak.tambah' }"
                role="button"
                >Tambah Data<i class="ri-add-line ml-2"></i
              ></router-link>
            </div> -->
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-50"
                    id="filter-input"
                    v-model="filterKontrak"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <div class="d-inline-block float-right">
                    <router-link
                      class="btn btn-primary dark-btn-primary"
                      :to="{ name: 'kerjasama.kontrak.tambah' }"
                      role="button"
                      >Tambah Data<i class="ri-add-line ml-2"></i
                    ></router-link>
                  </div>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="thead"
                :items="listData"
                class="mt-2"
                :busy="isLoadingKontrak"
                :filter="filterKontrak"
                :per-page="perPageKontrak"
                :current-page="currentPageKontrak"
                @filtered="onFilteredKontrak"
                show-empty
              >
                <template v-slot:cell(kodeMitra)="listData">
                  <div>
                    {{
                      listData.item.kodeMitra ? listData.item.kodeMitra : '-'
                    }}
                  </div>
                </template>
                <template v-slot:cell(termin)="listData">
                  <b-button
                    variant=" iq-bg-primary"
                    v-b-tooltip="'Tambah Termin'"
                    size="sm"
                    @click="showModalTermin(listData.item)"
                    ><i class="ri-add-line m-0"></i>Termin</b-button
                  >
                </template>
                <template v-slot:cell(realisasi)="listData">
                  <b-button
                    variant=" iq-bg-primary"
                    v-b-tooltip="'Tambah Realisasi'"
                    size="sm"
                    @click="showModalRealisasi(listData.item)"
                    ><i class="ri-add-line m-0"></i>Realisasi</b-button
                  >
                </template>
                <template v-slot:cell(aksi)="listData">
                  <div class="d-flex">
                    <router-link
                      class="btn iq-bg-success rounded-sm mr-1 mb-1"
                      v-b-tooltip="
                        listData.item.idStatusValidasi == 1
                          ? 'Adendum'
                          : 'Edit Data'
                      "
                      size="sm"
                      role="button"
                      :to="{
                        name: 'kerjasama.kontrak.edit',
                        params: { idPks: listData.item.idPks }
                      }"
                      ><i
                        :class="
                          listData.item.idStatusValidasi == 1
                            ? 'ri-book-2-fill m-0'
                            : 'ri-ball-pen-fill m-0'
                        "
                      ></i
                    ></router-link>
                    <!-- <b-button
                      class="mr-1 mb-1"
                      variant=" iq-bg-info"
                      v-b-tooltip="'Lihat Dokumen SK'"
                      size="sm"
                      @click="showDokumenSk"
                      ><i class="ri-book-line m-0"></i
                    ></b-button> -->
                    <router-link
                      class="btn iq-bg-info rounded-sm mr-1 mb-1"
                      v-b-tooltip="'Lihat Detail'"
                      size="sm"
                      role="button"
                      :to="{
                        name: 'kerjasama.kontrak.detail',
                        params: { idPks: listData.item.idPks }
                      }"
                      ><i class="ri-search-line m-0"></i
                    ></router-link>
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div class="text-danger font-weight-bold mx-auto text-center">
                    Data tidak ditemukan
                  </div>
                </template>
                <template #emptyfiltered>
                  <div class="text-danger font-weight-bold mx-auto text-center">
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPageKontrak"
                      :options="pageOptionsKontrak"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPageKontrak"
                    :total-rows="totalRowsKontrak"
                    :per-page="perPageKontrak"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
import axios from 'axios'
import store from '@/store'
import { mapState } from 'vuex'
import { ID_GROUP } from '@/types'
/** Date Format */
import moment from 'moment'

export default {
  name: 'kerjasama.kontrak',
  computed: {
    ...mapState('authService', ['token']),
    isValidTermin() {
      const status =
        this.termin.kodePks &&
        this.termin.angsuranKe &&
        this.termin.tglJatuhTempo &&
        this.termin.jumlahPembayaran !== null
      return status
    },
    isValidTerminEdit() {
      const status =
        this.terminEdit.kodePks &&
        this.terminEdit.angsuran &&
        this.terminEdit.tglJatuhTempo &&
        this.terminEdit.jumlahPembayaran !== null
      return status
    },
    isValidRealisasi() {
      const statusPjKeuanganPks =
        this.realisasi.verifikasi &&
        this.realisasi.keterangan_verifikasi !== null
      const statusDaak = this.realisasi.jaminan !== null
      const statusUpbjj = this.realisasi.lokasi_dokumen_jaminan !== null
      const status =
        this.realisasi.kodePks &&
        this.realisasi.angsuran &&
        this.realisasi.jumlah_angsuran &&
        this.realisasi.tanggal_pembayaran &&
        this.realisasi.cara_pembayaran &&
        this.realisasi.tanggal_jatuh_tempo &&
        this.realisasi.lokasi_dokumen &&
        this.realisasi.nomor_surat_tagihan !== null
      const statusPuskom =
        status && statusPjKeuanganPks && statusDaak && statusUpbjj

      if (this.listIdGroup.PJ_KEUANGAN_PKS.includes(this.idGroup)) {
        return statusPjKeuanganPks
      } else if (this.listIdGroup.DAAK.includes(this.idGroup)) {
        return statusDaak
      } else if (this.listIdGroup.UPBJJ.includes(this.idGroup)) {
        return statusUpbjj
      } else if (this.listIdGroup.PUSKOM.includes(this.idGroup)) {
        return statusPuskom
      } else {
        return status
      }
    }
  },
  mounted() {
    /** Innit data */
    this.innitData()
    /** Set the initial number of items */
    this.totalRowsRealisasi = this.dataRealisasi.length
  },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFilteredTermin(filteredItems) {
      this.totalRowsTermin = filteredItems.length
      this.currentPage = 1
    },
    onFilteredRealisasi(filteredItems) {
      this.totalRowsRealisasi = filteredItems.length
      this.currentPage = 1
    },
    onFilteredKontrak(filteredItems) {
      this.totalRowsKontrak = filteredItems.length
      this.currentPage = 1
    },
    /**
     * Reset Value
     */
    resetTermin() {
      this.termin.tglJatuhTempo = null
      this.termin.jumlahPembayaran = null
    },
    resetTerminEdit() {
      this.terminEdit.tglJatuhTempo = null
      this.terminEdit.jumlahPembayaran = null
    },
    resetRealisasi() {
      this.realisasi.kodePks = null
      this.realisasi.angsuran = null
      this.realisasi.jumlah_angsuran = null
      this.realisasi.tanggal_pembayaran = null
      this.realisasi.cara_pembayaran = null
      this.realisasi.tanggal_jatuh_tempo = null
      this.realisasi.lokasi_dokumen = null
      this.realisasi.nomor_surat_tagihan = null
      this.realisasi.verifikasi = null
      this.realisasi.keterangan_verifikasi = null
      this.realisasi.jaminan = null
      this.realisasi.lokasi_dokumen_jaminan = null
    },
    /**
     * Get data Kerjasama Kontrak
     */
    async getDataKontrak_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListTransaksiKontrak`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listData = res.data
          /** Set the initial number of items */
          this.totalRowsKontrak = this.listData.length
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get data Termin
     */
    async getDataTermin(kodePks) {
      this.isLoadingTermin = true
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListTermin?kodePks=${kodePks}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.dataTermin = res.data
          this.termin.angsuran = this.dataTermin.tahap
          this.listDataTermin = res.data.listTermin
          this.listDataTermin.map(
            t =>
              (t.tglJatuhTempo = moment(t.tglJatuhTempo).format('DD-MM-YYYY'))
          )
          /** Set the initial number of items */
          this.totalRowsTermin = this.listDataTermin.length
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.isLoadingTermin = false
      return result
    },
    /**
     * Termin
     */
    // create
    showModalTermin(data) {
      this.resetTermin()
      this.termin.kodePks = data.kodePks
      this.getDataTermin(this.termin.kodePks)
      this.isModalTermin = true
    },
    closeModalTermin() {
      this.resetTermin()
      this.isModalTermin = false
    },
    async createTermin() {
      const bodyFormData = new FormData()
      bodyFormData.append('kodePks', this.termin.kodePks)
      bodyFormData.append('angsuranKe', this.termin.angsuranKe)
      bodyFormData.append('tglJatuhTempo', this.termin.tglJatuhTempo)
      bodyFormData.append('jumlahPembayaran', this.termin.jumlahPembayaran)
      bodyFormData.append('nominalSisa', this.termin.sisaNominal)

      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/tambahDataTermin`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error(err.response.data.message)
          throw new Error(err)
        })
      this.getDataTermin(this.termin.kodePks)
      this.resetTermin()
    },
    // edit
    showModalEditTermin(kodePks, data) {
      this.resetTerminEdit()
      this.terminEdit.kodePks = kodePks
      this.terminEdit.angsuran = data.angsuran
      this.terminEdit.tglJatuhTempo = data.tglJatuhTempo
      this.terminEdit.jumlahPembayaran = data.jumlahPembayaran
      this.isModalEditTermin = true
    },
    closeModalEditTermin() {
      this.resetTerminEdit()
      this.isModalEditTermin = false
    },
    async editTermin() {
      const bodyFormData = new FormData()
      bodyFormData.append('kodePks', this.terminEdit.kodePks)
      bodyFormData.append('angsuran', this.terminEdit.angsuran)
      bodyFormData.append('tglJatuhTempo', this.terminEdit.tglJatuhTempo)
      bodyFormData.append('jumlahPembayaran', this.terminEdit.jumlahPembayaran)

      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/ubahDataTermin`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.getDataTermin(this.termin.kodePks)
      this.resetTerminEdit()
      this.isModalEditTermin = false
    },
    // delete
    showModalDeleteTermin(kodePks, data) {
      this.terminDelete.kodePks = kodePks
      this.terminDelete.angsuran = data.angsuran
      this.isModalDeleteTermin = true
    },
    async deleteTermin() {
      // console.log(this.terminDelete)
      const bodyFormData = new URLSearchParams()
      bodyFormData.append('kodePks', this.terminDelete.kodePks)
      bodyFormData.append('angsuran', this.terminDelete.angsuran)

      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/hapusDataTermin`,
        method: 'DELETE',
        data: bodyFormData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.getDataTermin(this.termin.kodePks)
      this.isModalDeleteTermin = false
    },
    /**
     * Realisasi
     */
    showDataRealisasi(event) {
      this.listDataTermin.map(t => {
        if (t.angsuran === parseInt(event)) {
          this.realisasi.jumlah_angsuran = t.jumlahPembayaran
          this.realisasi.tanggal_jatuh_tempo = t.tanggal_jatuh_tempo
        } else {
          this.realisasi.jumlah_angsuran = null
          this.realisasi.tanggal_jatuh_tempo = null
        }
      })
    },
    showModalRealisasi(data) {
      this.resetRealisasi()
      this.realisasi.kodePks = data.kodePks
      this.isModalRealisasi = true
    },
    closeModalRealisasi() {
      this.resetRealisasi()
      this.isModalRealisasi = false
    },
    createRealisasi() {
      this.$toast.info('Sedang dalam pengembangan')
    },
    showModalDeleteRealisasi(data) {
      this.realisasiDelete.angsuran = data.angsuran
      this.isModalDeleteRealisasi = true
    },
    deleteRealisasi() {
      this.$toast.info('Sedang dalam pengembangan')
      this.isModalDeleteRealisasi = false
    },
    /**
     * Dokumen SK
     */
    showDokumenSk() {
      this.$toast.info('Sedang dalam pengembangan')
    },
    /**
     * Init Data
     */
    async innitData() {
      /** Get List Data */
      this.isLoadingKontrak = true
      await Promise.all([this.getDataKontrak_()])
      this.isLoadingKontrak = false
    }
  },
  data() {
    return {
      listIdGroup: ID_GROUP,
      idGroup: store.state.authService.idGroup,
      // Tabel Termin Options
      isLoadingTermin: false,
      perPageTermin: 5,
      pageOptionsTermin: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPageTermin: 1,
      totalRowsTermin: 1,
      // Tabel Realisasi Options
      isLoadingRealisasi: false,
      perPageRealisasi: 5,
      pageOptionsRealisasi: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPageRealisasi: 1,
      totalRowsRealisasi: 1,
      // Tabel Kontrak Options
      isLoadingKontrak: false,
      filterKontrak: null,
      perPageKontrak: 5,
      pageOptionsKontrak: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPageKontrak: 1,
      totalRowsKontrak: 1,
      // isModal
      isModalTermin: null,
      isModalEditTermin: null,
      isModalDeleteTermin: null,
      isModalRealisasi: null,
      isModalDeleteRealisasi: null,
      // Date Picker
      basicConfig: {
        // dateFormat: 'd-m-Y'
      },
      basicMinTimeConfig: {
        minDate: moment(new Date()).format('YYYY-MM-DD')
      },
      // Termin
      termin: {
        idPksHeader: null,
        kodePks: null,
        angsuranKe: null,
        tglJatuhTempo: null,
        jumlahPembayaran: null,
        nominalKs: null,
        sisaNominal: null
      },
      terminEdit: {
        idPksHeader: null,
        kodePks: null,
        angsuran: null,
        tglJatuhTempo: null,
        jumlahPembayaran: null,
        nominalKs: null,
        sisaNominal: null
      },
      terminDelete: {
        idPksHeader: null,
        kodePks: null,
        angsuran: null,
        nominalKs: null,
        sisaNominal: null
      },
      // Realisasi
      realisasi: {
        kodePks: null,
        angsuran: null,
        jumlah_angsuran: null,
        tanggal_pembayaran: null,
        cara_pembayaran: null,
        tanggal_jatuh_tempo: null,
        lokasi_dokumen: null,
        nomor_surat_tagihan: null,
        verifikasi: null,
        keterangan_verifikasi: null,
        jaminan: null,
        lokasi_dokumen_jaminan: null
      },
      realisasiDelete: {
        angsuran: null
      },
      // Table
      thead: [
        {
          label: 'Kode KS',
          key: 'kodePks',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Nama KS',
          key: 'namaPks',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Kode Jenis KS',
          key: 'kodeJenisPks',
          class: 'text-center',
          sortable: true
        },
        { label: 'Keterangan', key: 'ruangLingkup', class: 'text-left w-50' },
        { label: 'Nomor KS UT', key: 'nomorKsUt', class: 'text-center' },
        {
          label: 'Nomor KS Mitra',
          key: 'nomorKsMitra',
          class: 'text-center'
        },
        {
          label: 'Kode Mitra',
          key: 'kodeMitra',
          class: 'text-center',
          sortable: true
        },
        // { label: 'Kontak Mitra', key: 'kontakMitra', class: 'text-center' },
        { label: 'Termin', key: 'termin', class: 'text-center' },
        { label: 'Realisasi', key: 'realisasi', class: 'text-center' },
        { label: 'Aksi', key: 'aksi', class: 'text-center' }
      ],
      listData: [],
      // Table Termin
      theadTermin: [
        { label: 'Angsuran Ke', key: 'angsuran', class: 'text-center' },
        {
          label: 'Tanggal Jatuh Tempo',
          key: 'tglJatuhTempo',
          class: 'text-center'
        },
        {
          label: 'Jumlah Pembayaran (x1000)',
          key: 'jumlahPembayaran',
          class: 'text-left w-50'
        },
        { label: 'Aksi', key: 'aksi', class: 'text-center w-25' }
      ],
      dataTermin: [],
      listDataTermin: [],
      // Table Realisasi
      theadRealisasi: [
        {
          label: 'Angsuran Ke',
          key: 'angsuran',
          class: 'text-center text-wrap'
        },
        {
          label: 'Jumlah Angsuran (x1000)',
          key: 'jumlah_angsuran',
          class: 'text-left'
        },
        {
          label: 'Tanggal Pembayaran',
          key: 'tanggal_pembayaran',
          class: 'text-center text-wrap'
        },
        {
          label: 'Cara Pembayaran',
          key: 'cara_pembayaran',
          class: 'text-center text-wrap'
        },
        {
          label: 'Verifikasi',
          key: 'verifikasi',
          class: 'text-center'
        },
        {
          label: 'Jaminan',
          key: 'jaminan',
          class: 'text-center'
        },
        { label: 'Dokumen', key: 'dokumen', class: 'text-center' },
        { label: 'Aksi', key: 'aksi', class: 'text-center' }
      ],
      dataRealisasi: [
        {
          id: 1,
          angsuran: 1,
          jumlah_angsuran: 46800,
          tanggal_pembayaran: '01-01-1900',
          cara_pembayaran: '',
          verifikasi: 1,
          jaminan: ''
        }
      ]
    }
  }
}
</script>
